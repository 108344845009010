<template>
  <div>
    <div class="login" v-if="!isLogin">
      <div class="loginMain">
        <h4>账号密码登录</h4>
        <div class="input">
          <p>用户名</p>
          <el-input v-model="phone" placeholder="用户名/邮箱/手机号"></el-input>
        </div>
        <div class="input">
          <p>密码</p>
          <el-input v-model="password" :type="isPassword ? 'password' : 'text'" placeholder="密码">
            <i @click="isPassword = !isPassword" style="cursor: pointer;" slot="suffix" class="el-input__icon" :class="isPassword ? 'el-icon-lock' : 'el-icon-unlock'"></i>
          </el-input>
        </div>
        <el-button type="primary" class="btn" @click="toLogin">登录</el-button>
      </div>
    </div>
    <zhongtuoIndex v-else></zhongtuoIndex>
  </div>
  
</template>

<script>
import zhongtuoIndex from './IndexZhongtuo'
document.ondragstart = function () {
  return false;
};
export default {
  name: "index",
  components:{zhongtuoIndex },
  data() {
    return {
      isPassword: true,
      isLogin: false,
      phone:'',
      password:'',

      truePhone: '18331351977',
      truePassword: '26059'
    };
  },
  async mounted() {
    this.isLogin = window.sessionStorage.getItem('isLogin') == this.truePhone ? true : false
  },
  computed: {
    
  },
  methods: {
    toLogin(){
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.password) {
        this.$toast("请输入密码码");
        return;
      }
      if(this.phone == this.truePhone && this.password == this.truePassword){
        window.sessionStorage.setItem('isLogin', this.truePhone)
        this.isLogin = true
      }else{
        this.$toast("账号密码错误");
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar{
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color:#F4F4F4;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC
}
::-webkit-scrollbar-thumb{
 border-radius:5px;
  background-color: #DEDEE4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.login{
  background: url("../assets/images/loginBg.png") top center no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginMain{
    width: 462px;
    background: rgba(255,255,255,0.8);
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    padding: 0 44px;
    h4{
      font-weight: 500;
      font-size: 18px;
      color: #262937;
      line-height: 25px;
      text-align: center;
      display: block;
      margin: 40px auto;
    }
    .input{
      margin-bottom: 24px;
      p{
        margin-bottom: 12px;
        font-size: 14px;
        color: #262937;
        line-height: 20px;
      }
    }
    .btn{
      width: 100%;
      margin-top: 32px;
      margin-bottom: 56px;
    }
  }
}
</style>
