<template>
  <div class="index" v-loading="pageLoading" ref="index">
     <div class="top w1200">
          <img src="../assets/images/logo.png"/>
          <b>托育职业教育系列</b>
          <p>本套教材系统理近年来我国托育服务发展的理论探索和实践经验，是我国托育专业教材建设的开山之作、奠基之作。</p>
          <ul>
            <li>
              <b>开创性</b>
              <p>首批职业教育托育专业教材，唯一国家级托育专业系列教材</p>
            </li>
            <li>
              <b>系统性</b>
              <p>囊括托育学科基本框架、基本内容和实践要求</p>
            </li>
            <li>
              <b>权威性</b>
              <p>行业专家骨干团队主编审核，理论阐释与实践演示并行</p>
            </li>
            <li>
              <b>适用性</b>
              <p>按照托育行业最新政策法规和标准规范编写</p>
            </li>
            <li>
              <b>融合性</b>
              <p>有效应用数字技术和新媒体在托育专业教学中</p>
            </li>
          </ul>
     </div>
     <div class="title w1200">
        <b>课程内容</b>
        <p>所用14本教材，是国家卫生健康委作为行业主管部门主持编写的首批职业教育托育专业教材，也是目前唯一国家级托育专业系列教材。</p>
     </div>
     <div class="list w1200">
        <div class="item" v-for="item in list" :key="item.id">
          <img :src="item.master_cover" class="img"/>
          <div class="cont">
            <b>{{item.name}}</b>
            <p>{{item.other}}</p>
          </div>
          <img src="../assets/images/play.png" class="play" @click="toDetail(item.id)"/>
        </div>
     </div>
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};

export default {
  name: "index",
  data() {
    return {
      pageLoading: false,
      list:[{id:744},{id:2}],
    };
  },
  async mounted() {
    await this.getData();
    
  },

  computed: {
  
  },

  methods: {
    
    //去详情
    toDetail(id) {
      let myUrl = `/detail2?id=${id}`;
      this.$router.push(myUrl)
    },
    async getData() {
      this.pageLoading = true;
      // this.$request.tuoyuCategory2({ flat_id: this.plat }).then((res) => {
      //   this.cateList = res.data;
      // });
      let res = await this.$api({
        method: "get",
        url: "/api/ping/list",
      });
      this.pageLoading = false;
      console.log(res.data.data)
      if (res.code == 0) {
        this.list = res.data.data.list
        console.log(this.list)
      } else {
        this.$toast(res.msg);
      }
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body {
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
$themeColor: #2e66d0;
.w1200{
  width: 1200px;
  margin: 0 auto;
}
.index {
  width: 100%;
  background: url('../assets/images/banner.png') top center no-repeat;
  background-size: 1920px;
  .top{
    padding-top: 120px;
    >b{
      display: block;
      font-weight: 600;
      font-size: 56px;
      color: #000000;
      line-height: 78px;
      font-style: normal;
      margin: 20px 0;
    }
    >p{
      width: 533px;
      font-weight: 400;
      font-size: 20px;
      color: #7A7E8E;
      line-height: 28px;
      font-style: normal;
      margin-bottom: 196px;
    }
    ul{
      height: 112px;
      background: rgba(255,255,255,0.9);
      box-shadow: 0px 0px 28px -15px rgba(42,49,67,0.18);
      border-radius: 12px;
      display: flex;
      li{
        flex: 1;
        text-align: center;
        b{
          display: block;
          font-weight: 600;
          font-size: 20px;
          color: #000000;
          line-height: 20px;
          font-style: normal;
          margin-bottom: 12px;
          margin-top: 20px;
        }
        p{
          font-weight: 400;
          font-size: 14px;
          color: #787C8D;
          line-height: 20px;
          width: 200px;
          margin: 0 auto;
        }
      }
    }
  }
  .title{
    margin-top: 100px;
    text-align: center;
    margin-bottom: 63px;
    b{
      font-weight: 600;
      font-size: 40px;
      color: #000000;
      line-height: 56px;
      letter-spacing: 2px;
      margin-bottom: 14px;
      display: block;
      font-style: normal;
    }
    p{
      width: 800px;
      font-weight: 400;
      font-size: 18px;
      color: #7A7E8E;
      line-height: 25px;
      font-style: normal;
      margin: 0 auto;
    }
  }
  .list{
    margin-bottom: 40px;
    .item{
      height: 248px;
      background: #FFFFFF;
      border-radius: 20px;
      border: 1px solid #ECECEC;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      padding: 24px 44px 24px 24px;
      .img{
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
      .play{
        cursor: pointer;
      }
      .cont{
        flex: 1;
        padding-left: 40px;
        padding-right: 116px;
        b{
          display: block;
          margin-bottom: 26px;
          font-weight: 600;
          font-size: 24px;
          color: #000000;
          line-height: 32px;
          font-style: normal;
        }
        p{
          font-weight: 400;
          font-size: 16px;
          color: #999999;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}

</style>
